function setCookie(name, value, days) {
    let d = new Date;
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

class Cookies {

    static init() {
        function getCookie(name) {
            var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
            return v ? v[2] : null;
        }

        if (getCookie('popUpCookies') !== '1') {
            setTimeout(function(){
                const popUpTrigger = document.getElementById('homePopUp');
                if (popUpTrigger) {
                    popUpTrigger.checked = true;
                    $.scrollify.disable();
                }
            }, 2000);
        }
    }
}

document.addEventListener("DOMContentLoaded", function() {
    Cookies.init();
});
